import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import PropTypes from 'prop-types';

import {  BROADCASTERS } from '../broadcaster';
import './TopBar.scss';

export default class TopBar extends React.Component {
  renderBroadcasterDropdown() {
    const items = [];
    Object.values(BROADCASTERS).forEach(broadcaster => {
      items.push(
        <Dropdown.Item
          key={broadcaster.id}
          href={'/?broadcasterId=' + broadcaster.id}>
          <img className="broadcaster-dropdown-icon" src={broadcaster.icon} alt="broadcaster icon" />
          {broadcaster.name}
        </Dropdown.Item>
      );
    })

    const selectedBroadcaster = Object.values(BROADCASTERS).find(b => b.id === this.props.broadcasterId);
    const title = (
      <span>
        <img className="selected-broadcaster-icon" src={selectedBroadcaster.icon} alt="broadcaster icon" />
        {selectedBroadcaster.name}
      </span>
    );
    return (
      <DropdownButton className="broadcaster-dropdown" title={title}>
        {items}
      </DropdownButton>
    )
  }

  render() {
    return (
      <div className="top-bar">
        {this.renderBroadcasterDropdown()}
        <div className="top-bar-title">
          <a href="/">
            HAchubby & Friends Clip Search
            <img className="top-bar-lulclip" src="lulclip.png" alt="lulclip" />
          </a>
        </div>
      </div>
    )
  }
}


TopBar.prototypes = {
  broadcasterId: PropTypes.string.isRequired
}
import React from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.scss';
import TopBar from './components/TopBar';
import Clips from './components/Clips';
import SearchFilter from './components/SearchFilter';
import {getApiEndpoint} from './util';
import {  BROADCASTERS } from './broadcaster';

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.search = this.search.bind(this);

    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const broadcasterId = params.get('broadcasterId') || BROADCASTERS.hachubby.id;
    this.state = {
      loading: false,
      broadcasterId,
      clipSearchFilters: null,
      clipSearchResult: null,
    }
  }

  search(clipSearchFilters, shouldPushState) {
    this.setState({loading: true, clipSearchFilters});

    let url = getApiEndpoint() + '/search';
    const params = new URLSearchParams();
    params.set('broadcasterId', this.state.broadcasterId)
    if (clipSearchFilters) {
      params.set('page', clipSearchFilters.page || 1);
      if (clipSearchFilters.title) {
        params.set('title', clipSearchFilters.title);
      }
      if (clipSearchFilters.creatorName) {
        params.set('creatorName', clipSearchFilters.creatorName);
      }
      if (clipSearchFilters.startDate) {
        params.set('startDate', clipSearchFilters.startDate.toISOString());
      }
      if (clipSearchFilters.endDate) {
        params.set('endDate', clipSearchFilters.endDate.toISOString());
      }
      if (clipSearchFilters.game) {
        params.set('game', clipSearchFilters.game);
      }
      const viewsMin = parseInt(clipSearchFilters.viewsMin);
      if (!isNaN(viewsMin)) {
        params.set('viewsMin', viewsMin);
      }
      const viewsMax = parseInt(clipSearchFilters.viewsMax);
      if (!isNaN(viewsMax)) {
        params.set('viewsMax', viewsMax);
      }
      if (clipSearchFilters.orderBy) {
        params.set('orderBy', clipSearchFilters.orderBy);
      }
    }
    url = url + "?" + params.toString();
    if (shouldPushState) {
      window.history.pushState({}, '', '?' + params.toString());
    }

    axios.get(url).then(result => {
      this.setState({clipSearchResult: result.data});
    }).catch(e => {
      if (e && e.response && e.response.status && e.response.status === 429) {
        toast.error("Error! Too many requests!");
      } else if (e && e.response && e.response.status && e.response.status === 400) {
        toast.error("Error! Bad request!");
      } else {
        toast.error("Error! Failed to load clips!");
      }
      this.setState({clipSearchResult: null});
    }).finally(() => {
      this.setState({loading: false});
    });
  }

  render() {
    return (
      <div className="App">
        <TopBar broadcasterId={this.state.broadcasterId} />
        <div className="body">
          <SearchFilter 
            loading={this.state.loading}
            clipSearch={this.search}
            broadcasterId={this.state.broadcasterId} />
          <Clips
            loading={this.state.loading}
            clipSearchResult={this.state.clipSearchResult} 
            clipSearchFilters={this.state.clipSearchFilters}
            clipSearch={this.search}/>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

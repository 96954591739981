import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import axios from 'axios';

import {isValidDate, getApiEndpoint} from '../util';
import {BROADCASTERS} from '../broadcaster';
import './SearchFilter.scss';

const OrderBy = {
  VIEW_COUNT: "VIEW_COUNT",
  DATE_NEWEST: "DATE_NEWEST",
  DATE_OLDEST: "DATE_OLDEST",
  RELEVANCE_TITLE: "RELEVANCE_TITLE"
}

class CustomDateInput extends React.Component {
  render() {
    const {
      onClick,
      value,
    } = this.props;

    return <Form.Control type="text" value={value} onClick={onClick} readOnly placeholder="Select..." />
  }
}

export default class SearchFilter extends React.Component {
  constructor(props) {
    super(props);
    this.onSearchButtonClick = this.onSearchButtonClick.bind(this);
    this.onTitleChange = this.onTitleChange.bind(this);
    this.onCreatorNameChange = this.onCreatorNameChange.bind(this);
    this.onStartDateChanged = this.onStartDateChanged.bind(this);
    this.onEndDateChanged = this.onEndDateChanged.bind(this);
    this.onOrderByChanged = this.onOrderByChanged.bind(this);
    this.loadGames = this.loadGames.bind(this);
    this.onGameSelected = this.onGameSelected.bind(this);
    this.search = this.search.bind(this);
    this.parseUrlQueryString = this.parseUrlQueryString.bind(this);
    this.updateSearchParams = this.updateSearchParams.bind(this);
    this.onToggleAdditionalFilters = this.onToggleAdditionalFilters.bind(this);
    this.onViewsMinChanged = this.onViewsMinChanged.bind(this);
    this.onViewsMaxChanged = this.onViewsMaxChanged.bind(this);

    const broadcaster = Object.values(BROADCASTERS).find(b => b.id === props.broadcasterId);
    const minYear = (broadcaster && broadcaster.startYear) ? broadcaster.startYear : 2016;
    this.minDate = new Date(minYear, 0, 1);
    this.maxDate = new Date();


    this.state = {
      searchParams: this.parseUrlQueryString(),
      games: null,
      loadingGames: false,
      showAdditionalFilters: false,
    }
  }

  componentDidMount(){
    window.onpopstate = () => {
      setTimeout(() => {
        this.setState({searchParams: this.parseUrlQueryString()}, () => this.search(false));
      }, 0);
    };
    window.addEventListener('keyup', e => {
      if (e.key === 'Enter') {
        this.onSearchButtonClick();
      }
    });
    if (this.state.searchParams.game) {
      this.loadGames();
    }
    this.search(false);
  }

  parseUrlQueryString() {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const title = params.get('title') || '';
    let startDate = new Date(params.get('startDate'));
    if (!isValidDate(startDate) || startDate < this.minDate || startDate > this.maxDate) {
      startDate = null;
    }
    let endDate = new Date(params.get('endDate'));
    if (!isValidDate(endDate) ||  endDate < this.minDate || endDate > this.maxDate) {
      endDate = null;
    }
    const creatorName = params.get('creatorName') || '';
    const game = params.get('game') || null;
    const orderByKey = params.get('orderBy');
    const orderBy = OrderBy[orderByKey] || OrderBy.RELEVANCE_TITLE;
    const page = parseInt(params.get('page')) || 1;
    const viewsMin = parseInt(params.get('viewsMin'));
    const viewsMax = parseInt(params.get('viewsMax'));

    return {
      title,
      startDate,
      endDate,
      creatorName,
      game,
      orderBy,
      page,
      viewsMin: isNaN(viewsMin) ? '' : viewsMin,
      viewsMax: isNaN(viewsMax) ? '' : viewsMax
    }
  }

  search(shouldPushState) {
    const clipSearchFilters = Object.assign({}, this.state.searchParams);
    this.props.clipSearch(clipSearchFilters, shouldPushState);
  }

  updateSearchParams(params, callback) {
    const newSearchParams = Object.assign({}, this.state.searchParams, params);
    this.setState({searchParams: newSearchParams}, callback);
  }

  onToggleAdditionalFilters() {
    this.setState({showAdditionalFilters: !this.state.showAdditionalFilters});
  }

  onSearchButtonClick() { 
    this.updateSearchParams({page: 1}, () => this.search(true))
  }

  onTitleChange(event) {
    this.updateSearchParams({ title: event.target.value })
  }

  onCreatorNameChange(event) {
    this.updateSearchParams({ creatorName: event.target.value });
  }

  onStartDateChanged(date)  {
    this.updateSearchParams({ startDate: date });
  }

  onEndDateChanged(date) {
    this.updateSearchParams({ endDate: date });
  }

  onOrderByChanged(event) {
    this.updateSearchParams({ orderBy: event.target.value });
  }

  onViewsMinChanged(event) {
    this.updateSearchParams({ viewsMin: event.target.value });
  }

  onViewsMaxChanged(event) {
    this.updateSearchParams({ viewsMax: event.target.value });
  }

  loadGames() {
    if (!this.state.games && !this.state.loadingGames) {
      // Load games
      this.setState({loadingGames: true});
      const url = getApiEndpoint() + '/game?broadcasterId=' + this.props.broadcasterId;
      axios.get(url).then(result => {
        const gameOptions = result.data.map(game => {
          return {value: game.id, label: game.name}
        }).sort((game1, game2) => game1.label.localeCompare(game2.label));
        this.setState({games: gameOptions, loadingGames: false});
      }).catch(e => {
        console.error('Failed to load games from twitch.', e);
        this.setState({loadingGames: false});
      });
    }
  }

  onGameSelected(game)  {
    this.updateSearchParams({ game: (game ? game.value : null) });
  }

  render() {
    const games = this.state.games || [];
    return (
      <div className="search-filter">
        <Form className="search-filter-form">
          <Form.Group controlId="title" className="search-filter-form-title">
            <Form.Label>Title</Form.Label>
            <Form.Control type="text" maxLength={100} onChange={this.onTitleChange} value={this.state.searchParams.title} />
          </Form.Group>
          <Accordion>
            <Accordion.Collapse eventKey="0">
              <div>
                <Form.Group controlId="start-date">
                  <Form.Label>Start date</Form.Label>
                  <DatePicker
                    selected={this.state.searchParams.startDate}
                    onChange={this.onStartDateChanged}
                    isClearable
                    showMonthDropdown
                    showYearDropdown
                    customInput={<CustomDateInput />}
                    minDate={this.minDate}
                    maxDate={this.maxDate}
                  />
                </Form.Group>
                <Form.Group controlId="end-date">
                  <Form.Label>End date</Form.Label>
                  <DatePicker
                    selected={this.state.searchParams.endDate}
                    onChange={this.onEndDateChanged}
                    isClearable
                    showMonthDropdown
                    showYearDropdown
                    customInput={<CustomDateInput />}
                    minDate={this.minDate}
                    maxDate={this.maxDate}
                  />
                </Form.Group>
                <Form.Group controlId="creator-name">
                  <Form.Label>Creator name</Form.Label>
                  <Form.Control type="text" maxLength={25} onChange={this.onCreatorNameChange} value={this.state.searchParams.creatorName} />
                </Form.Group>
                <Form.Group controlId="game">
                  <Form.Label>Game</Form.Label>
                  <Select 
                    searchable={true}
                    isClearable={true}
                    options={games} 
                    onFocus={this.loadGames}
                    isLoading={this.state.loadingGames}
                    onChange={this.onGameSelected}
                    value={games.filter(g => g.value === this.state.searchParams.game)}
                    className="game-select"
                  />
                </Form.Group>
                <Form.Row>
                  <Form.Group as={Col} controlId="views-min">
                    <Form.Label>Views min</Form.Label>
                    <Form.Control type="number" min={0} onChange={this.onViewsMinChanged} value={this.state.searchParams.viewsMin} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="views-max">
                    <Form.Label>Views max</Form.Label>
                    <Form.Control type="number" min={0} onChange={this.onViewsMaxChanged} value={this.state.searchParams.viewsMax} />
                  </Form.Group>
                </Form.Row>
                <Form.Group controlId="order-by">
                  <Form.Label>Order by</Form.Label>
                  <Form.Control as="select" value={this.state.searchParams.orderBy} onChange={this.onOrderByChanged} >
                    <option value={OrderBy.VIEW_COUNT}>View count</option>
                    <option value={OrderBy.DATE_NEWEST}>Date (newest)</option>
                    <option value={OrderBy.DATE_OLDEST}>Date (oldest)</option>
                    <option value={OrderBy.RELEVANCE_TITLE}>Relevance (title)</option>
                  </Form.Control>
                </Form.Group>
              </div>
            </Accordion.Collapse>
            <Accordion.Toggle 
              as={Button} 
              variant="link" 
              eventKey="0" 
              className="search-filter-additional-filters-btn"
              onClick={this.onToggleAdditionalFilters}>
              {this.state.showAdditionalFilters ? "Show less filters" : "Show more filters"}
            </Accordion.Toggle>
          </Accordion>
          <Button
            className="search-button"
            variant="primary"
            disabled={this.props.loading}
            onClick={this.onSearchButtonClick}>
            Search
          </Button>
        </Form>
      </div>
    )
  }
}

SearchFilter.prototypes = {
  loading: PropTypes.bool,
  clipSearch: PropTypes.object,
  broadcasterId: PropTypes.string.isRequired
}

export const BROADCASTERS = {
  hachubby: {
    name: 'HAchubby',
    id: '195166073',
    icon: '195166073.png',
    startYear: 2018
  },
  jinnytty: {
    name: 'Jinnytty',
    id: '159498717',
    icon: '159498717.png',
    startYear: 2017
  },
  krustingkevin: {
    name: 'KrustingKevin',
    id: '537803959',
    icon: '537803959.png',
    startYear: 2020
  },
  dantheliontv: {
    name: 'DanTheLionTV',
    id: '558769154',
    icon: '558769154.png',
    startYear: 2020
  },
  charming_jo: {
    name: 'Charming_Jo',
    id: '134433708',
    icon: '134433708.png',
    startYear: 2016
  },
  yuggie_tv: {
    name: 'Yuggie_TV',
    id: '235634967',
    icon: '235634967.png',
    startYear: 2020
  },
  yunicorn19: {
    name: 'Yunicorn19',
    id: '130727794',
    icon: '130727794.png',
    startYear: 2016
  },
  tyongeee: {
    name: 'tyongeee',
    id: '432065909',
    icon: '432065909.png',
    startYear: 2019
  },
  nymn: {
    name: 'NymN',
    id: '62300805',
    icon: '62300805.png',
    startYear: 2016
  },
  plushys: {
    name: 'Plushys',
    id: '84569419',
    icon: '84569419.png',
    startYear: 2016
  },
};
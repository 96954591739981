export function isValidDate(date) {
  return date instanceof Date && !isNaN(date);
}

export function getApiEndpoint() {
  let endpoint = process.env.REACT_APP_API_ENDPOINT
  if (!endpoint) {
    endpoint = window.location.origin + '/api/v1';
  }
  return endpoint;
}
import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'

import './Clips.scss';

export default class Clips extends React.Component {
  constructor(props) {
    super(props);

    this.clipsRef = React.createRef();
  }

  componentDidUpdate() {
    if (this.clipsRef && this.clipsRef.current) {
      this.clipsRef.current.scrollTo(0, 0);
    }
  }

  loadPage(page) {
    if (page < 1 || page > this.props.clipSearchResult.totalPages) {
      return;
    }

    const clipSearchFilters = Object.assign({}, this.props.clipSearchFilters, { page });
    this.props.clipSearch(clipSearchFilters, true);
  }

  renderPagination() {
    // First page is page 1
    const page = this.props.clipSearchResult.page;
    const totalPages = this.props.clipSearchResult.totalPages;

    return (
      <div className="clips-pagination">
        <Button
          variant="dark"
          className="clips-pagination-first" 
          onClick={() => this.loadPage(1)}
          disabled={page === 1}>
          &lt;&lt;
        </Button>
        <Button
          variant="dark"
          className="clips-pagination-previous"
          onClick={() => this.loadPage(page - 1)}
          disabled={page === 1}>
          &lt;
        </Button>
        <div className="clips-pagination-text">
          Page {page} of {totalPages}
        </div>
        <Button
          variant="dark"
          className="clips-pagination-next"
          onClick={() => this.loadPage(page + 1)}
          disabled={page === (totalPages)}>
          &gt;
        </Button>
        <Button
          variant="dark"
          className="clips-pagination-last"
          onClick={() => this.loadPage(totalPages)}
          disabled={page === (totalPages)}>
          &gt;&gt;
        </Button>
      </div>
    )
  }

  renderLoader() {
    return (
      <div className="clips-loader">
        <Spinner animation="border" />
      </div>
    )
  }

  renderClips() {
    const clips = this.props.clipSearchResult.clips || [];

    if (clips.length === 0) {
      return (
        <div className="clips-list-noclipsfound">
          <img className="clips-list-noclipsfound-img" src="hachuSad.png" alt="hachuSad" />
          No clips found. 
        </div>
      )
    }

    return (
      <div className="clips" ref={this.clipsRef}>
        <div className="clips-container">
          <div className="clips-card-deck">
            {clips.map((clip, index) => this.renderClip(clip, index))}
          </div>
        </div>
        {this.renderPagination()}
      </div>
    )
  }

  renderClip(clip, index) {
    const date = new Date(clip.createdAt);

    return (
      <div key={index} className="clip-card">
        <div className="clip-card-thumbnail">
          <a href={clip.url} target="_blank" rel="noopener noreferrer"> 
            <img src={clip.thumbnailUrl} alt="clip thumbnail" className="clip-card-thumbnail-img" />
          </a>
          <div className="clip-card-viewcount">{clip.viewCount} views</div>
          <div className="clip-card-createdat">{date.toDateString()}</div>
        </div>
        <div className="clip-card-footer">
          <div className="clip-card-title" title={clip.title}>
            <a href={clip.url} target="_blank" rel="noopener noreferrer"> 
              {clip.title}
            </a>
          </div>
          <div className="clip-card-creator">
            Clipped by {clip.creatorName}
          </div>
        </div>
      </div>
    )
  }

  render() {
    if (this.props.loading) {
      return this.renderLoader();
    } else if(this.props.clipSearchResult) {
      return this.renderClips();
    }

    return null;
  }
}

Clips.prototypes = {
  loading: PropTypes.bool,
  clipSearchFilters:  PropTypes.object,
  clipSearchResult: PropTypes.object,
  clipSearch: PropTypes.object,
}
